import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const sizes = {
  sm: 'h-8 w-8',
  md: 'h-14 w-14',
  lg: 'h-20 w-20'
}

const Avatar = props => {
  const { url, label, size, className } = props
  return (
    url
      ? <img src={url} alt="avatar" className={classNames('rounded-full', className, sizes[size])} />
      : (
        <div className={classNames(className, { 'text-sm': size === 'sm',
          'text-xl': size === 'md',
          'text-2xl': size === 'lg' },
        sizes[size],
        'bg-gray text-black rounded-full  font-semibold flex items-center justify-center uppercase')}
        >
          {label}
        </div>
      )
  )
}

Avatar.propTypes = {
  url: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  label: PropTypes.string,
  className: PropTypes.string,
}

Avatar.defaultProps = {
  url: '',
  size: 'md',
  label: '',
  className: '',
}

const Memoized = React.memo(Avatar)
export default Memoized
