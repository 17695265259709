const getAccountEndpoints = builder => ({
  repayPaymentOrder: builder.mutation({
    query: ({ accountId, repayableId, body }) => ({
      url: `/accounts/${accountId}/payment_orders/${repayableId}/repay`,
      method: 'POST',
      body,
    })
  }),
  linkPaymentToBiller: builder.mutation({
    query: ({ accountUuid, paymentId, body }) => ({
      url: `accounts/${accountUuid}/payments/${paymentId}/link_bill`,
      method: 'POST',
      body,
    })
  }),
  repaySubscriptionBill: builder.mutation({
    query: ({ accountId, repayableId, body }) => ({
      url: `/accounts/${accountId}/subscription_bills/${repayableId}/repay`,
      method: 'POST',
      body,
    })
  }),
  writeOffSubscriptionBill: builder.mutation({
    query: ({ accountId, subscriptionBillId, body }) => ({
      url: `/accounts/${accountId}/subscription_bills/${subscriptionBillId}/write_off`,
      method: 'POST',
      body,
    })
  }),
  archiveBank: builder.mutation({
    query: ({ accountId, body }) => ({
      url: `/accounts/${accountId}/archive_bank_account`,
      method: 'PUT',
      body,
    })
  }),
  unlockBank: builder.mutation({
    query: ({ accountId, ...body }) => ({
      url: `/accounts/${accountId}/unlock_bank`,
      method: 'POST',
      body,
    })
  }),
  lockAccount: builder.mutation({
    query: ({ accountId, body }) => ({
      url: `/accounts/${accountId}/lock`,
      method: 'PUT',
      body,
    })
  }),
  closeAccount: builder.mutation({
    query: ({ accountId, body }) => ({
      url: `/accounts/${accountId}/close`,
      method: 'PUT',
      body,
    })
  }),
  changeFraudType: builder.mutation({
    query: ({ accountId, body }) => ({
      url: `/accounts/${accountId}/change_fraud_type`,
      method: 'PUT',
      body,
    })
  }),
  overrideSpendLimit: builder.mutation({
    query: ({ accountId, body }) => ({
      url: `/accounts/${accountId}/override_spend_limit`,
      method: 'PUT',
      body,
    })
  }),
  pauseBillingCard: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/pause_card`,
      method: 'PUT',
      body
    })
  }),
  unpauseBillingCard: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/unpause_card`,
      method: 'PUT',
      body
    })
  }),
  replaceBillingCard: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/replace_card`,
      method: 'PUT',
      body
    })
  }),
  issueBankruptcy: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/issue_bankruptcy`,
      method: 'PUT',
      body
    })
  }),
  retryCreditServiceRegistration: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/retry_credit_service_registration`,
      method: 'POST',
      body
    })
  }),
  resetDocumentVerification: builder.mutation({
    query: ({ accountId, body }) => ({
      url: `/accounts/${accountId}/reset_document_verification`,
      method: 'PUT',
      body
    })
  }),
  updateProfile: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}`,
      method: 'PUT',
      body
    }),
  }),
  getAccountList: builder.query({
    query: (params = {}) => {
      const {
        perPage,
        query,
        selectedFilters,
        page,
        sortField,
        sortDirection,
      } = params
      const currentpage = page || 1
      const data = { per_page: perPage || 4, page: currentpage, sort_field: sortField, sort_direction: sortDirection }
      const search = query || null

      if (search) data.q = search

      if (selectedFilters) {
        if (selectedFilters?.categories?.length) {
          data.categories = selectedFilters?.categories?.join(',')
        }
        if (selectedFilters?.priorities?.length) {
          data.priority = selectedFilters?.priorities?.join(',')
        }
        if (selectedFilters?.statuses?.length) {
          data.status = selectedFilters?.statuses?.join(',')
        }
      }
      return {
        url: '/accounts',
        params: data,
      }
    },
  }),
  uploadDocument: builder.mutation({
    query: ({ accountUuid, file }) => {
      const formData = new FormData()
      formData.append('document', file)

      return {
        url: `/accounts/${accountUuid}/user_documents`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
      }
    },
    // TODO: this endpoint should invalidate some account-related tags (once we fully migrate to RTK Query)
    // invalidatesTags: (_, error) => (error ? [] : [ACCOUNT_TAG_TYPE]),
  }),
  refundPayment: builder.mutation({
    query: ({ accountUuid, paymentId }) => ({
      url: `/accounts/${accountUuid}/payments/${paymentId}/refund`,
      method: 'PUT',
    }),
  }),
  refundOverCollection: builder.mutation({
    query: ({ accountUuid, paymentId }) => ({
      url: `/accounts/${accountUuid}/payments/${paymentId}/refund_over_collection`,
      method: 'PUT',
    }),
  }),
  fraudTransaction: builder.mutation({
    query: ({ accountUuid, paymentId, fraudLabel }) => {
      const formData = new FormData()
      formData.append('fraud_label', fraudLabel)

      return {
        url: `/accounts/${accountUuid}/payments/${paymentId}/fraud`,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
      }
    },
  }),
  fetchAccountAlerts: builder.query({
    query: ({ accountUuid }) => ({
      url: `/accounts/${accountUuid}/alerts`,
    }),
  }),
  fetchAccountEmails: builder.query({
    query: ({ accountUuid }) => ({
      url: `/accounts/${accountUuid}/emails_sent`,
    }),
  }),
  fetchAccountEvents: builder.query({
    query: ({ accountUuid, searchQuery, page }) => ({
      url: `/accounts/${accountUuid}/events?q=${searchQuery}&page=${page}`,
    }),
  }),
  fetchAccountPaymentOrders: builder.query({
    query: ({ accountUuid, ...params }) => ({
      url: `/accounts/${accountUuid}/payment_orders`,
      params,
    }),
  }),
  fetchAccountTransactions: builder.query({
    query: ({ accountUuid, ...params }) => ({
      url: `/accounts/${accountUuid}/transactions`,
      params,
    }),
  }),
  fetchAccountBillPayCards: builder.query({
    query: ({ accountUuid }) => ({
      url: `/accounts/${accountUuid}/bill_pay_cards`,
    }),
  }),
  forgetAccount: builder.mutation({
    query: ({ accountUuid, body }) => ({
      url: `/accounts/${accountUuid}/forget_user`,
      method: 'PUT',
      body,
    }),
  }),
  fetchAccountAgreements: builder.query({
    query: ({ accountUuid }) => ({
      url: `/accounts/${accountUuid}/agreements`,
    }),
  }),
})

export default getAccountEndpoints
